import i18n from 'i18next';
import { i18nextConfig } from './i18next.config';
import enUs from './locales/en-US';
import ptBr from './locales/pt-BR';
import esES from './locales/es-ES';

i18n.init({
  resources: {
    'en-US': enUs,
    'pt-BR': ptBr,
    'es-ES': esES,
  },
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
  ...i18nextConfig,
});

export default i18n;

'use client';

// This import must go at the top
import '../lib/http/setup';
import { createDscFromThemeSetting } from '@mentimeter/ragnar-ui';
import { ThemeProvider } from 'react-fela'; // keep import from react-fela to avoid getting Tailwind theme twice
import {
  addResourceBundles,
  registerResourceBundles,
  MentimeterI18nInitializer,
  i18nextConfig,
} from '@mentimeter/i18n';
import { locales as authLocales } from '@mentimeter/auth';
import { locales as plansContentLocales } from '@mentimeter/plans-content';
import { locales as checkoutUiPackageLocales } from '@mentimeter/checkout-ui';
import { locales as checkoutPackageLocales } from '@mentimeter/checkout';
import { locales as billingAddressPackageLocales } from '@mentimeter/billing-address';
import { locales as checkoutModalPackageLocales } from '@mentimeter/checkout-modal';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import { ModalProvider } from '@mentimeter/legacy-modal';
import { ToastsProvider } from '@mentimeter/toast';
import i18n from '@mentimeter/i18n/i18nextInit';
import { HotkeyProvider } from '@mentimeter/hotkeys';
import Cookies from '@mentimeter/cookies';
import { I18nextProvider } from 'react-i18next';
import { MobileMenuProvider } from 'components/MobileMenuProvider';
import {
  invoiceRequestBundle,
  planBoxBundle,
  manageRolesBundle,
} from '../../locales';
import { RegionalProvider } from './RegionalProvider';
import FelaProvider from './FelaProvider';

// Register translations for auth package
registerResourceBundles(authLocales);

addResourceBundles(i18n, [
  billingAddressPackageLocales,
  checkoutModalPackageLocales,
  checkoutPackageLocales,
  checkoutUiPackageLocales,
  plansContentLocales,
  invoiceRequestBundle,
  planBoxBundle,
  manageRolesBundle,
]);

export function Providers({ children }: { children: React.ReactNode }) {
  const uiTheme = Cookies.get('ui-theme');
  const { theme } = createDscFromThemeSetting(uiTheme);

  const breakpoints = theme.breakpoints.map((b) => stripAllNonNumericChars(b));

  return (
    <DeviceProvider breakpoints={breakpoints}>
      <ThemeProvider theme={theme}>
        <MobileMenuProvider>
          <FelaProvider>
            <HotkeyProvider>
              <RegionalProvider>
                <ModalProvider>
                  <ToastsProvider>
                    <I18nextProvider i18n={i18n}>
                      <MentimeterI18nInitializer
                        config={i18nextConfig}
                        language={i18n.language}
                      >
                        {children}
                      </MentimeterI18nInitializer>
                    </I18nextProvider>
                  </ToastsProvider>
                </ModalProvider>
              </RegionalProvider>
            </HotkeyProvider>
          </FelaProvider>
        </MobileMenuProvider>
      </ThemeProvider>
    </DeviceProvider>
  );
}

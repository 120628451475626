import { getUserLocale } from './getUserLocale';
import type { NextI18NextInitConfig } from './i18n';

const lng = getUserLocale();

export const i18nextConfig: NextI18NextInitConfig = {
  supportedLngs: ['en-US', 'pt-BR', 'es-ES', 'de-DE'],
  fallbackLng: 'en-US',
  react: {
    useSuspense: false,
  },
  ...(lng ? { lng } : {}),
};

const LOADED_FONTS_CACHE = {};

interface FontT {
  import?: string;
  family: string;
  name: string;
  weight: number | string;
  bold: number | string;
}

export interface FontSettingT {
  key: string;
  name: string;
  body: string;
  heading?: string;
}

// @ts-expect-error-auto TS(7006) FIXME: Parameter 'css' implicitly has an 'any' type.
const addCss = (css) => {
  const head = document.getElementsByTagName('head')[0];
  const s = document.createElement('style');
  s.setAttribute('type', 'text/css');
  s.innerHTML = css;
  head?.appendChild(s);
};

export const loadEditorFonts = (style: { [key: string]: any }) => {
  /* this plugin checks every Ragnar style object for the `fontFamily` key,
  then tries to check if we have defined the CSS where it is defined. If we do, we add that
  CSS to the DOM. */
  if (style.fontFamily) {
    // Pre-empt if already loaded.
    // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (LOADED_FONTS_CACHE[style.fontFamily]) {
      return style;
    }
    const font = getFontByFamily(style.fontFamily);
    if (font && font.url) {
      // Load the font by legacy definitions and the old way as it was in `applyTheme`
      addCss(`@import url(${font.url});`);
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      LOADED_FONTS_CACHE[style.fontFamily] = true;
    }
  }
  return style;
};

/*
 * Fonts
 */
const createCustomFontUrl: (arg0: {
  host?: string;
  name: string;
}) => string = ({ host = process.env.NEXT_PUBLIC_STATIC_ASSET_HOST, name }) =>
  `${host}/static/fonts/web/${name}/font.css`;

const FONTS: {
  [key: string]: FontT;
} = {
  'menti-text': {
    family: 'MentiText, Arial, sans-serif',
    name: 'MentiText',
    // Imported by fela in Renderer.js
    weight: 400,
    bold: 600,
  },
  'menti-display': {
    family: 'MentiDisplay, Arial, sans-serif',
    name: 'MentiDisplay',
    // Imported by fela in Renderer.js
    weight: 400,
    bold: 600,
  },
  gilroy: {
    family: 'Gilroy, sans-serif',
    name: 'Gilroy',
    import: createCustomFontUrl({ name: 'gilroy' }),
    weight: 500,
    bold: 700,
  },
  'paul-slab': {
    family: 'PaulSlab, serif',
    name: 'PaulSlab',
    import: createCustomFontUrl({ name: 'paul-slab' }),
    weight: 400,
    bold: 600,
  },
  'nexa-rust': {
    family: 'NexaRustSans, sans-serif',
    name: 'NexaRustSans',
    import: createCustomFontUrl({ name: 'nexa-rust' }),
    weight: 400,
    bold: 400,
  },
  'geon-soft': {
    family: 'GeonSoft, sans-serif',
    name: 'GeonSoft',
    import: createCustomFontUrl({ name: 'geon-soft' }),
    weight: 400,
    bold: 'bold',
  },
  'darwin-rounded': {
    family: 'DarwinProRd, sans-serif',
    name: 'DarwinProRd',
    import: createCustomFontUrl({ name: 'darwin-rounded' }),
    weight: 400,
    bold: 'bold',
  },
  'ibm-plex-mono': {
    family: 'IBM Plex Mono, monospace',
    name: 'IBM Plex Mono',
    import: createCustomFontUrl({ name: 'ibm-plex-mono' }),
    weight: 400,
    bold: 700,
  },
  poppins: {
    family: 'Poppins, sans-serif',
    name: 'Poppins',
    weight: 400,
    bold: 600,
  },
  'playfair-display': {
    family: 'PlayfairDisplay, sans-serif',
    name: 'PlayfairDisplay',
    import: createCustomFontUrl({ name: 'playfair-display' }),
    weight: 400,
    bold: 600,
  },
  catamaran: {
    family: 'Catamaran, sans-serif',
    name: 'Catamaran',
    import: createCustomFontUrl({ name: 'catamaran' }),
    weight: 400,
    bold: 600,
  },
  helvetica: {
    family: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    name: 'Helvetica',
    weight: 400,
    bold: 600,
  },
  arial: {
    family: 'Arial, sans-serif',
    name: 'Arial',
    weight: 400,
    bold: 600,
  },
};

const BY_FAMILY = Object.keys(FONTS).reduce((acc, key) => {
  const data = FONTS[key];
  return {
    ...acc,
    // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
    [data.family]: { url: data.import, name: data.name },
  };
}, {});

export const getFontByKey = (family: string) => FONTS[family];

// @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const getFontByFamily = (family: string) => BY_FAMILY[family];

/*
 * Font usage:
 * Make sure the font used will be easy to read in visualizations, otherwise use a secondary font.
 * For Quick Slides, the fonts can be more fancy as those slides have the purpose of being more
 * interesting, but should of course be easy to read as well.
 *
 * Keep the visualization headings (questions) easy to read and make sure long questions work well.
 */
export const FONT_SETTINGS: {
  [key: string]: FontSettingT;
} = {
  menti: {
    name: 'Menti',
    key: 'menti-text',
    heading: 'menti-display',
    body: 'menti-text',
  },
  gilroy: {
    name: 'Gilroy',
    key: 'gilroy',
    body: 'gilroy',
  },
  'paul-slab': {
    name: 'Paul Slab',
    key: 'paul-slab',
    body: 'paul-slab',
  },
  'nexa-rust': {
    name: 'Nexa Rust',
    key: 'nexa-rust',
    heading: 'nexa-rust',
    body: 'gilroy',
  },
  'geon-soft': {
    name: 'Geon Soft',
    key: 'geon-soft',
    body: 'geon-soft',
  },
  'darwin-rounded': {
    name: 'Darwin',
    key: 'darwin-rounded',
    body: 'darwin-rounded',
  },
  'ibm-plex-mono': {
    name: 'Plex Mono',
    key: 'ibm-plex-mono',
    body: 'ibm-plex-mono',
  },
  poppins: {
    name: 'Poppins',
    key: 'poppins',
    body: 'poppins',
  },
  'playfair-display': {
    name: 'Playfair Display',
    key: 'playfair-display',
    body: 'playfair-display',
  },
  catamaran: {
    name: 'Catamaran',
    key: 'catamaran',
    body: 'catamaran',
  },
  helvetica: {
    name: 'Helvetica',
    key: 'helvetica',
    body: 'helvetica',
  },
  arial: {
    name: 'Arial',
    key: 'arial',
    body: 'arial',
  },
};
